module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"dir":"ltr","lang":"en-US","name":"Prashant's Portfolio","display":"standalone","start_url":"/","short_name":"my Portfolio","theme_color":"#0242a8","description":"I am Prashant. I am currently a Btech Student and this is my portfolio.","orientation":"any","background_color":"#FFFFFF","related_applications":[],"prefer_related_applications":false,"icons":[{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"/favicon.ico","sizes":"150x150","type":"image/x-icon"},{"src":"/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicon-16x16.png","sizes":"16x16","type":"image/png"}],"screenshots":[{"src":"/banner.jpg","sizes":"1280x720","type":"image/jpeg"}],"categories":["education","social"],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Q1CFLJQJYH"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false,"delayOnRouteUpdate":0,"exclude":[],"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
